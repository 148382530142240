import React from "react";

const Android = () => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">Android Development</h3>

            <div className="skills__box">
                <div className="skills__group">
                    <div className="skills__data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills__name">Gradle</h3>
                        <span className="skills__level">Build Automation System</span>
                    </div>
                    </div>

                    <div className="skills__data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills__name">Kotlin + Java</h3>
                        <span className="skills__level">Primary Development Language</span>
                    </div>
                    </div>

                    <div className="skills__data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills__name">Bitmovin Player SDK (Android)</h3>
                        <span className="skills__level">Mobile Video Playback Engine</span>
                    </div>
                    </div>
                </div>

                {/* <div className="skills__group">
                    <div className="skills__data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills__name">Bootstrap</h3>
                        <span className="skills__level">Basic</span>
                    </div>
                    </div>

                    <div className="skills__data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills__name">Git</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                    </div>

                    <div className="skills__data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills__name">React</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Android