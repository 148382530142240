import React from "react";

const Android = () => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">Ai Development</h3>

            <div className="skills__box">
                <div className="skills__group">
                    <div className="skills__data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills__name">Python</h3>
                        <span className="skills__level">Core Development Language</span>
                    </div>
                    </div>

                    <div className="skills__data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills__name">Stanford Parser</h3>
                        <span className="skills__level">Natural Language Processing</span>
                    </div>
                    </div>

                    <div className="skills__data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills__name">Stanza</h3>
                        <span className="skills__level">Syntactic Parsing for AI</span>
                    </div>
                    </div>
                </div>

                {/* <div className="skills__group">
                    <div className="skills__data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills__name">Bootstrap</h3>
                        <span className="skills__level">Basic</span>
                    </div>
                    </div>

                    <div className="skills__data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills__name">Git</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                    </div>

                    <div className="skills__data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills__name">React</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Android